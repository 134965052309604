header {
    position: relative;
    /* height: 800px; */
    min-height: 500px;
    width: 100%;
    background: var(--color-background-landing-page) url(../../public/assets/IMG_CANNON.jpg) no-repeat 50% 36%;
    -webkit-background-size: cover !important;
    background-size: cover !important;
    text-align: center;
    overflow: hidden;
    height: 100vh;
 }
 
 /* vertically center banner */
 header:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
 }
 
 header .banner {
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    width: 85%;
    padding-bottom: 30px;
    text-align: center;
 }
 
 header .banner-text { width: 100%; }
 header .banner-text h1 {
    font: 90px/1.1em 'opensans-bold', sans-serif;
    color: var(--color-text-light);
    letter-spacing: -2px;
    margin: 0 auto 18px auto;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);
 }
 
 header .banner-text h3 {
    font: 18px/1.9em 'librebaskerville-regular', serif;
    color: var(--color-text-alt-light);
    /* font-weight: 400; */
    margin: 0 auto;
    width: 70%;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, .5);
 }
 
 header .banner-text h3 span, header .banner-text h3 a {
    color: var(--color-text-light);
 }
 
 header .banner-text hr {
    width: 60%;
    margin: 18px auto 24px auto;
    border-color: #2F2D2E;
    border-color: rgba(150, 150, 150, .1);
 }
 
 /* Header Social Links */
 header .social {
    margin: 24px 0;
    padding: 0;
    font-size: 30px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);
 }
 
 header .social li {
    display: inline-block;
    margin: 0 15px;
    padding: 0;
 }
 
 header .social li a { color: #ffffff; }
 header .social li a:hover { color: #11abb0; }
 
 /* Scrolldown */
 header .scrolldown a {
   position: absolute;
   bottom: 30px;
   left: 50%;
   margin-left: -29px;
   color: #ffffff;
   display: inline-block;
   height: 42px;
   width: 42px;
   font-size: 42px;
   line-height: 42px;
   color: #fff;
   border-radius: 100%;

   /* property name | duration | easing function | delay */
   -webkit-transition: all 300ms ease-in-out;
   -moz-transition: all 300ms ease-in-out;
   -o-transition: all 300ms ease-in-out;
   -ms-transition: all 300ms ease-in-out;
   transition: all 300ms ease-in-out;
 }
 
 header .scrolldown a:hover { color: #ff6600; }