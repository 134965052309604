/* .submit {
  background-color: black;
  border-radius: 5px;
  padding: 20px;
} */

.skillsGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    grid-auto-rows: auto;
    height: 100vh;
  }
  
  .skillsGrid img {
    height: 100px;
    width: 100px;
  }
  
  .skills h2 {
    margin-bottom: 2em;
  }
  
  .skills {
    background-color: var(--color-background-alt-light);
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  /* Small screens: max width 640px, change to 4 coloumns */
  @media screen and (max-width: 640px) {
    .skillsGrid{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      grid-auto-rows: auto;
      height: auto;
    }
  
    .skillsGrid img {
      height: 75px;
      width: 75px;
    }
  
    .skills {
      background-color: var(--color-background-alt-light);
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      padding-top: 50px;
      padding-bottom: 32px;
      text-align: center;
      justify-content: center;
      align-items: center;
      }
  }
  
  /* Medium screens: min width 641px, change to 4 coloumns */
  @media screen and (min-width: 641px) {
    .skillsGrid{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
      grid-auto-rows: auto;
      height: auto;
    }
  
    .skillsGrid img {
      height: 100px;
      width: 100px;
    }
  
    .skills{
      background-color: var(--color-background-alt-light);
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      padding-top: 70px;
      padding-bottom: 52px;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
  
  /* Large screens: min width 1080px, change to 8 coloums */
  @media screen and (min-width: 1195px) {
    .skillsGrid{
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 50px;
      grid-auto-rows: auto;
      height: auto;
    }
  
    .skillsGrid img {
      height: 150px;
      width: 150px;
    }
  
    .skills {
      background-color: var(--color-background-alt-light);
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      padding-top: 90px;
      padding-bottom: 72px;
      text-align: center;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
  }